/* eslint-disable no-prototype-builtins */
import React, { useContext, useEffect, useState, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CustomizedSnackbars from "components/elements/customizedSnackbars";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";

import Table from "./table";
import useStyles from "./TerminalsTableStyles";

//Context
import UpdateDataContext from "context/update/UpdateDataContext";
import DisableTableContext from "context/disabled/DisableTableContext";
import TabsContext from "context/pages/home/diagnostics/TabsContext";

import { useForm /* , Resolver */, Controller } from "react-hook-form";
import colors from "constants/colors";
//Service
import TerminalService from "services/terminal.service";
//Mixpanel
import mixpanel from "mixpanel-browser";

import { /* withStyles, */ makeStyles } from "@mui/styles";
import { default as NumberFormat } from "react-number-format";
import Metrics from "./Metrics/Metrics";
import ErrorBoundary from "components/errorBoundary";
import AlertCustom from "components/alert";
import { Skeleton, Card } from "@mui/material";

const useStyles2 = (value: any) =>
  makeStyles((theme) => ({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: acquireValidationColor(value),
      },
      "& .Mui-error": {
        color: acquireValidationColor(value),
      },
      "& .MuiFormHelperText-root": {
        color: acquireValidationColor(value),
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#B6C5D5",
        },
      },
    },
  }));

const acquireValidationColor = (message: any) => {
  switch (message) {
    case "ID de Terminal es requerido":
      return "#DD2457";
    case "Debes ingresar 8 dígitos":
      return "#DD2457";
    default:
      return "";
  }
};
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumberFormatCustom = forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        /* thousandSeparator */
        isNumericString
        prefix=""
        maxLength={8}
      />
    );
  }
);
const ValidationTextField = ({
  helperText,
  label,
  error,
  onChange,
  placeholder,
  onClickFunction,
}: any) => {
  const classes = useStyles2(helperText)();
  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onClickFunction();
    }
  };
  return (
    <TextField
      name="numberformat"
      label={label}
      helperText={helperText}
      className={classes.root}
      error={error}
      placeholder={placeholder}
      multiline
      variant="standard"
      fullWidth
      style={{
        marginBottom: error ? "-0.6rem" : ".8rem",
      }}
      onChange={onChange}
      InputProps={{
        style: {
          fontFamily: "Rubik",
          fontSize: "16px",
          fontWeight: 400,
          color: colors.grayText,
        },
        inputComponent: NumberFormatCustom as any,
      }}
      onKeyDown={keyDownHandler}
    />
  );
};

type FormValues = {
  filterTerminal: string;
};

const TerminalsTable = (/* { terminalData }: any */) => {
  const styles = useStyles();
  const [termminalsData, setTermminalsData] = useState<any>();
  const [content, setContent] = useState<any>();
  const [pagePosition, setPage] = useState<number>();
  const [pageSize, setSize] = useState<number>();
  const [totalElements, setTotalElements] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [valorData, setValorData] = useState<boolean>(false);
  const [terminalFiltered, setTerminalFiltered] = useState<string>("");
  const [customizedSnackbars, setCustomizedSnackbars] =
    useState<boolean>(false);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(true);
  //Context
  const { setUpdateData, updateData } = useContext(UpdateDataContext);
  const { disableTable, setDisableTabla } = useContext(DisableTableContext);
  const { setDiagnosticOpen } = React.useContext(TabsContext);
  const [errorCodeAlert, setErrorCodeAlert] = useState<string>("");

  //form hooks
  const data = {
    filterTerminal: "",
  };
  const {
    control,
    handleSubmit,
    //clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: data });

  const sendData = (data: FormValues) => {
    //filter only id terminal in 8 characters
    if (data.filterTerminal.length === 8) {
      //analytics
      mixpanel.track("apply_filter_button", {
        Screen: "Diagnostics - Terminals List",
        Terminal: data.filterTerminal,
      });

      if (data.filterTerminal === "44444444") {
        setCustomizedSnackbars(true);
      } else {
        fetchIdTerminal(1, data.filterTerminal);
      }
    }
  };

  const fetchIdTerminal = async (pagePosition: number, idTerminal: string) => {
    setDisableTabla(true);
    try {
      setDiagnosticOpen(false);
      localStorage.setItem("diagnostic_open", "");
      setTermminalsData("");
      const res = await TerminalService.terminal_list(pagePosition, idTerminal);
      if (res.status === 200) {
        localStorage.setItem("terminals", JSON.stringify(res.data));
        setTermminalsData(JSON.parse(localStorage.getItem("terminals") || ""));
        setUpdateData(true);
        setTerminalFiltered(idTerminal);
        setDisableTabla(false);
      }
    } catch (error) {
      console.log(error);
      setCustomizedSnackbars(true);
      setDisableTabla(false);
    }
  };

  useEffect(() => {
    if (!termminalsData) {
      //localStorage.removeItem('terminals')
      setTermminalsData(JSON.parse(localStorage.getItem("terminals") || ""));
      setUpdateData(true);
    } else {
      setDisableTabla(false);
    }
    //cambio de update en true y al mismo tiempo si se actualizo los valores en true
    if ((updateData && valorData) || !!termminalsData) {
      setContent(termminalsData?.content);
      setPage(termminalsData?.page);
      setSize(termminalsData?.size);
      setTotalElements(termminalsData?.total_elements);
      setTotalPages(termminalsData?.total_pages);
      setUpdateData(false);
      setValorData(false);
    }
  }, [valorData]);

  //preguntar si en algun punto se actualizo updateDate = true
  useEffect(() => {
    if (updateData) {
      setTermminalsData(JSON.parse(localStorage.getItem("terminals") || ""));
      setValorData(true); //validar el update de la info
      //setFilterLoading(true);

      setDisableTabla(false);
    }
  }, [updateData]);

  useEffect(() => {
    if (customizedSnackbars) {
      //analytics
      mixpanel.track("error_message_view", {
        Screen: "Diagnostics - Terminals List",
      });
    }
  }, [customizedSnackbars]);

  const [metrics, setMetrics] = React.useState<any>();
  const fetchTerminalMetrics = async () => {
    try {
      const res = await TerminalService.terminals_metrics();
      if (res.status === 200 && res.data.length != 0) {
        setMetrics(res.data);
        setShowError(false);
        setLoadingMetrics(false);
        return res.data;
      }
    } catch (error: any) {
      setLoadingMetrics(false);
      setShowError(true);
      setErrorCodeAlert(error?.response?.status || error?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    setLoadingMetrics(true);
    fetchTerminalMetrics();
  }, []);

  const handleClickAlert = () => {
    //mixpanel
    mixpanel.track("retry_button", {
      Screen: "Diagnostics - Metrics Panel",
    });
  };

  return (
    <div style={{ padding: "0rem 1rem" }}>
      <Card className={styles.cardLayout}>
        <Typography className={styles.textHeader}>
          Diagnóstico de Terminales
        </Typography>
        <Grid className={styles.tableContainer}>
          {customizedSnackbars && (
            <CustomizedSnackbars
              severity={"warning"}
              message={
                "Algo salío mal y no podemos mostrar la información en este momento."
              }
              setCustomizedSnackbars={setCustomizedSnackbars}
            />
          )}
          <Grid className={styles.textHeaderContainer}>
            <Controller
              control={control}
              name="filterTerminal"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: "ID de Terminal es requerido",
                },
                minLength: {
                  value: 8,
                  message: "Debes ingresar 8 dígitos",
                },
              }}
              render={({ field }) => (
                <ValidationTextField
                  helperText={errors?.filterTerminal?.message || ""}
                  error={errors.hasOwnProperty("filterTerminal")}
                  placeholder={"Ingresá el ID de la terminal"}
                  key={1}
                  label={
                    <span style={{ color: colors.grayText }}>
                      ID de Terminal
                    </span>
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event?.target?.value?.length <= 0) {
                      fetchIdTerminal(1, "");
                    }
                    //reset clean errors
                    reset();
                    field.onChange(event);
                  }}
                  onClickFunction={handleSubmit(sendData)}
                />
              )}
            />
            {!disableTable ? (
              <IconButton
                color="primary"
                className={styles.buttomFilter}
                onClick={handleSubmit(sendData)}
              >
                Aplicar
                <FilterIcon style={{ paddingLeft: ".5rem" }} />
              </IconButton>
            ) : (
              <IconButton color="primary" className={styles.buttomFilter}>
                <div
                  className="rotateIcon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "2rem",
                    height: "2rem",
                  }}
                >
                  <LoadingIcon />
                </div>
              </IconButton>
            )}
          </Grid>
        </Grid>
        {terminalFiltered !== "" ? (
          <div>
            <Grid className={styles.tableContent}>
              {content && (
                <Table
                  terminals={content}
                  pagePosition={pagePosition}
                  pageSize={pageSize}
                  totalElements={totalElements}
                  totalPages={totalPages}
                />
              )}
            </Grid>
          </div>
        ) : null}
      </Card>
      <Card className={styles.cardLayout}>
        <Typography className={styles.textHeader}>
          Parque activo de terminales
        </Typography>
        <ErrorBoundary>
          {!loadingMetrics ? (
            !showError || metrics !== undefined ? (
              <Metrics metrics={metrics} />
            ) : (
              <div style={{ height: "1000px", margin: "-8rem" }}>
                <AlertCustom
                  message_error={errorCodeAlert}
                  message_error_description={errorCodeAlert}
                  onClick2={() => (handleClickAlert(), fetchTerminalMetrics())}
                />
              </div>
            )
          ) : (
            <Skeleton
              variant="rectangular"
              style={{
                background: colors.background,
                borderRadius: "10px",
                minWidth: "600px",
                height: "600px",
              }}
              width="100%"
              height="98%"
            />
          )}
        </ErrorBoundary>
      </Card>
      <div
        style={{
          marginTop: "auto",
          margin: "3em",
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Rubik",
            fontSize: "0.85rem",
            fontWeight: 100,
            lineHeight: "1.25rem",
            fontStyle: "light",
            color: colors.gray3,
          }}
        >
          Dashboard Diagnóstico - Versión 1.6.2.1
        </Typography>
      </div>
    </div>
  );
};

export default TerminalsTable;
