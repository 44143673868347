import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  Snackbar,
  TextField,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorBoundary from "components/errorBoundary";
import useStyles from "./styles";
import colors from "constants/colors";
import CardSetting from "./CardSetting";
import moment from "moment";
import { useKeycloak } from "@react-keycloak/web";
import RoleContext from "context/userRole/RoleContext";
import TerminalService from "services/terminal.service";
import { Switch } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DialogCustom from "components/dialogCustom/DialogCustom";
import WarningIcon from "@mui/icons-material/Warning";
import mixpanel from "mixpanel-browser";
import InfoAlert from "./InfoAlert";
import EnableOnRole from "helpers/EnableOnRole";

const switchTheme = createTheme({
  palette: {
    primary: {
      main: colors.cyanDark,
    },
    secondary: {
      main: colors.cyanLight,
    },
  },
});

interface Props {
  state?: any;
  merchant?: string;
  terminal?: string;
}

const Settings = ({ state, merchant, terminal }: Props) => {
  const styles = useStyles();
  const { keycloak } = useKeycloak();
  const { role } = useContext(RoleContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openCashbackDialog, setOpenCashbackDialog] =
    React.useState<boolean>(false);
  const [openApiModeDialog, setOpenApiModeDialog] =
    React.useState<boolean>(false);
  const [openUnrefRefundDialog, setOpenUnrefRefundDialog] =
    React.useState<boolean>(false);
  const [openPrismaDialog, setOpenPrismaDialog] =
    React.useState<boolean>(false);
  const [openAMEXDialog, setOpenAMEXDialog] = React.useState<boolean>(false);
  const [currentCashback, setCurrentCashback] = React.useState<boolean>(state.allowCashback);
  const [currentAPIMode, setCurrentAPImode] = React.useState<any>(null);
  const [currentPrismaMerchant, setCurrentPrismaMerchant] =
    React.useState<string>("");
  const [currentAmexMerchant, setCurrentAmexMerchant] =
    React.useState<string>("");
  const [newPrismaMerchant, setNewPrismaMerchant] = React.useState<string>("");
  const [newAmexMerchant, setNewAmexMerchant] = React.useState<string>("");
  const [openPkgDialog, setOpenPkgDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [specificPkg, setSpecificPkg] = React.useState([]);
  const [tefAcqErrorSnackbar, setTefAcqErrorSnackbar] = React.useState(false);

  const enabledProducts =
    state?.enabledProducts.map(function (prod: any) {
      return (prod = { ...prod, enabled: true });
    }) || [];
  const availableProducts =
    state?.availableProducts.map(function (prod: any) {
      return (prod = { ...prod, enabled: false });
    }) || [];

  const cardsUnified = enabledProducts.concat(availableProducts);

  const [cardList, setCardList] = useState<any>(
    cardsUnified ? cardsUnified : null
  );

  //ROLES
  const userRole = role;

  const momentObj = moment(state.batchCloseDailyTime, ["h:mm"]);

  useEffect(() => {
    getSpecificPkg();
    fetchTefAcquirers(validMID, validTID);
    getApiModeCurrentState(validMID, validTID);
  }, []);

  const handleClose = () => {
    setOpenCashbackDialog(false);
    setOpenApiModeDialog(false);
    setOpenUnrefRefundDialog(false);
    setOpenPkgDialog(false);
    setOpenPrismaDialog(false);
    setOpenAMEXDialog(false);
    setNewPrismaMerchant("");
    setNewAmexMerchant("");
  };

  const handleCashback = () => {
    setOpenCashbackDialog(true);
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify Cobro y retiro de efectivo (Débito)",
    });
  };

  const handleApiMode = () => {
    setOpenApiModeDialog(true);
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify modo API",
    });
  };

  const handleUnrefRefMode = () => {
    setOpenUnrefRefundDialog(true);
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify Devoluciones no referenciadas",
    });
  };

  const handlePrisma = () => {
    setOpenPrismaDialog(true);
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify Prisma",
    });
  };

  const handleAMEX = () => {
    setOpenAMEXDialog(true);
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify AMEX",
    });
  };

  const fetchTefAcquirers = async (merchant: string, terminal: string) => {
    try {
      const res = await TerminalService.fetch_tef_acquirers(merchant, terminal);
      if (res.status === 200) {
        res.data.forEach((acq: any) => {
          acq.tef_acquirer === "13" && acq.enabled === true
            ? setCurrentPrismaMerchant(acq.tef_terminal)
            : "No disponible";
          acq.tef_acquirer === "14" && acq.enabled === true
            ? setCurrentAmexMerchant(acq.tef_terminal)
            : "No disponible";
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const updateTefAcquirers = async (
    merchant: string,
    tefAcquirer: string,
    terminal: string,
    terminalAcquirerId: string
  ) => {
    try {
      const res = await TerminalService.update_tef_acquirers({
        merchant: merchant,
        tef_acquirer: tefAcquirer,
        terminal: terminal,
        terminal_acquirer_id: terminalAcquirerId,
      });
      if (res.status === 200) {
        window.location.reload();
        mixpanel.track("settings_tab", {
          Screen: "Configuración - Modify número adquirencia",
          Modificación: tefAcquirer === "13" ? "Prisma" : "AMEX",
        });
        return res.data;
      }
    } catch (error: any) {
      setTefAcqErrorSnackbar(true);
      console.log(error);
    }
  };

  const getApiModeCurrentState = async (merchant: string, terminal: string) => {
    try {
      const res = await TerminalService.get_apimode(merchant, terminal);
      if (res.status === 200) {
        setCurrentAPImode(res.data.currentValue);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const validTID: string = localStorage.getItem("validTID") || "";
  const validMID: string = localStorage.getItem("validMID") || "";

  async function allowCashbackAPI(cashbackType: string) {
    try {
      setLoading(true);
      const res = await TerminalService.switch_cashback(merchant, terminal);
      
      if (!res || res.status !== 200) return;
      setCurrentCashback(res.data.currentValue);
      
      mixpanel.track("settings_tab", {
        Screen: `Configuración - Modifica Cobro y retiro de efectivo ${cashbackType}`,
        Modificación: res.data.currentValue,
      });
    } catch (error: any) {
      console.error("Error:", error?.response?.status, error?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  }
  

  async function toggleAPIMode() {
    try {
      setLoading(true);
      const res = await TerminalService.switch_apimode(merchant, terminal);
      res ? setLoading(false) : null;
      if (res.status === 200) {
        setCurrentAPImode(res.data.currentValue);
        mixpanel.track("settings_tab", {
          Screen: "Configuración - Modify modo API",
          Modificación: res.data.currentValue,
        });
      }
    } catch (error: any) {
      console.log(error?.response?.status.toString());
      console.log(error?.message.toString());
    }
    handleClose();
  }

  async function toggleUnrefRefund() {
    try {
      setLoading(true);
      const res = await TerminalService.switch_unreferenced(merchant, terminal);
      res ? setLoading(false) : null;
      if (res.status === 200) {
        state.allowUnreferencedRefund = res.data.currentValue;
        mixpanel.track("settings_tab", {
          Screen: "Configuración - Modify Devoluciones no referenciadas",
          Modificación: res.data.currentValue,
        });
      }
    } catch (error: any) {
      console.log(error?.response?.status.toString());
      console.log(error?.message.toString());
    }
    handleClose();
  }

  async function getSpecificPkg() {
    try {
      const res = await TerminalService.fetch_specific_package(
        terminal,
        merchant
      );
      if (res.status === 200) {
        setSpecificPkg(res.data);
      }
    } catch (error: any) {
      console.log(error?.response?.status.toString());
      console.log(error?.message.toString());
    }
  }

  const descriptionCashback = `Esta funcionalidad contempla la operatoria de retiro en efectivo en compras con tarjeta presente.
  
  Recordá validar la repro antes de realizar esta modificación.

  ¿Deseas continuar?

`;

  const descriptionCashbackQR = `Esta funcionalidad contempla la operatoria de retiro en efectivo en compras con QR.
  
  Recordá validar la repro antes de realizar esta modificación.

  ¿Deseas continuar?

`;

  const descriptionAPIMode = `El cobro con Modo API oculta la aplicación de pagos, limitando al comercio a cobrar con la terminal integrada a su sistema de caja.
                            
                          Para cobrar normalmente con la terminal, este modo deberá estar deshabilitado.

¿Deseas continuar?

`;

  const descriptionUnrefRefund = `Con esta opción habilitada, el comercio podrá hacer devoluciones parciales.

¿Deseas continuar?

`;

  const prismaMaxLength = 8;
  const amexMaxLength = 10;

  const descriptionPrisma = (
    <TextField
      error={newPrismaMerchant.length !== prismaMaxLength ? true : false}
      name="numberformat"
      label="Comercio Prisma"
      placeholder={currentPrismaMerchant}
      autoComplete="off"
      variant="standard"
      style={{ width: "320px", margin: "1em", marginBottom: "1em" }}
      InputProps={{
        style: {
          fontFamily: "Rubik",
          fontSize: "18px",
          fontWeight: 400,
          color: colors.grayText,
        },
      }}
      type="number"
      helperText={
        newPrismaMerchant.toString().length !== prismaMaxLength
          ? `El número de comercio debe contener ${prismaMaxLength} caracteres.`
          : null
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setNewPrismaMerchant(e.target.value.toString())
      }
    />
  );

  const descriptionAmex = (
    <TextField
      error={newAmexMerchant.length !== amexMaxLength ? true : false}
      name="numberformat"
      label="Comercio Amex"
      placeholder={currentAmexMerchant}
      autoComplete="off"
      variant="standard"
      style={{ width: "320px", margin: "1em", marginBottom: "1em" }}
      InputProps={{
        style: {
          fontFamily: "Rubik",
          fontSize: "18px",
          fontWeight: 400,
          color: colors.grayText,
        },
      }}
      type="number"
      helperText={
        newAmexMerchant.length !== amexMaxLength
          ? `El número de comercio debe contener ${amexMaxLength} caracteres.`
          : null
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setNewAmexMerchant(e.target.value.toString())
      }
    />
  );

  const validManufacturer = localStorage.getItem("validManufacturer");

  const handleClickOpen = () => {
    getSpecificPkg();
    setOpenPkgDialog(true);
  };

  const copyPkgToClipboard = () => {
    setOpenSnackbar(true);
    navigator.clipboard.writeText(JSON.stringify(specificPkg, null, 2));
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setTefAcqErrorSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={{ padding: "1em" }}>
      <InfoAlert />
      {/* Configuración + switches */}
      <Card className={styles.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={6} xl={6}>
            <Card
              className={styles.cardInternal}
              style={{
                paddingInline: "1.5em",
                minHeight: "360px"
              }}
            >
              <Grid className={styles.gridInternal}>
                <Typography
                  className={styles.textCardSetting}
                  style={{
                    marginLeft: "1em",
                    marginTop: "1.2em",
                    marginBottom: "1em",
                  }}
                >
                  Números de establecimiento
                </Typography>
                <Grid container>
                  <Grid xs={12} className={styles.configGridContent}>
                    <Typography className={styles.textTittle2}>
                      Adquirencia PRISMA:
                    </Typography>
                    <Typography className={styles.textSub}>
                      {currentPrismaMerchant
                        ? currentPrismaMerchant
                        : "No habilitado"}
                    </Typography>
                    <IconButton
                      disabled={
                        !currentPrismaMerchant || (role === "Operador" || role === "Consulta")
                          ? true
                          : false
                      }
                      style={{
                        backgroundColor:
                          !currentPrismaMerchant || (role === "Operador" || role === "Consulta")
                            ? colors.grayDisabled
                            : colors.primary,
                      }}
                      className={styles.buttomFilter}
                      onClick={handlePrisma}
                    >
                      Modificar
                    </IconButton>
                  </Grid>
                  <Grid xs={12} className={styles.configGridContent}>
                    <Typography className={styles.textTittle2}>
                      Adquirencia AMEX:
                    </Typography>
                    <Typography className={styles.textSub}>
                      {currentAmexMerchant
                        ? currentAmexMerchant
                        : "No habilitado"}
                    </Typography>
                    <IconButton
                      disabled={
                        !currentAmexMerchant || (role === "Operador" || role === "Consulta")
                          ? true
                          : false
                      }
                      style={{
                        backgroundColor:
                          !currentAmexMerchant || (role === "Operador" || role === "Consulta")
                            ? colors.grayDisabled
                            : colors.primary,
                      }}
                      className={styles.buttomFilter}
                      onClick={handleAMEX}
                    >
                      Modificar
                    </IconButton>
                  </Grid>
                  <Grid xs={12} className={styles.configGridContentButton}>
                    <Button
                      style={{
                        backgroundColor:
                          (role === "Operador" || role === "Consulta") ? colors.grayDisabled : "primary",
                      }}
                      className={styles.buttomPkg}
                      disabled={(role === "Operador" || role === "Consulta") ? true : false}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleClickOpen();
                      }}
                    >
                      Ver configuración específica
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} xl={6}>
            <Card
              className={styles.cardInternal}
              style={{
                paddingInline: "1.5em",
                minHeight: "360px"
              }}
            >
              <Typography
                className={styles.textCardSetting}
                style={{
                  marginLeft: "1em",
                  marginTop: "1.2em",
                  marginBottom: "1em",
                }}
              >
                Configuración de terminal
              </Typography>
              <Grid container>
                <Grid xs={12} className={styles.configGridContent}>
                  <Typography className={styles.textSub}>
                    Cobro y retiro de efectivo (Débito)
                  </Typography>
                  <ThemeProvider theme={switchTheme}>
                    <Switch
                      key={"allowCashbackSwitch"}
                      checked={currentCashback}
                      onChange={handleCashback}
                      inputProps={{ "aria-label": "controlled" }}
                      name={"allowCashbackSwitch"}
                      disabled={!EnableOnRole(['Administrador'])}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid xs={12} className={styles.configGridContent}>
                  <Typography className={styles.textSub}>
                    Cobro y retiro de efectivo (QR)
                  </Typography>
                  <ThemeProvider theme={switchTheme}>
                    <Switch
                      key={"allowCashbackQRSwitch"}
                      checked={state.allowCashbackQR}
                      inputProps={{ "aria-label": "controlled" }}
                      name={"allowCashbackQRSwitch"}
                      disabled={true}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid xs={12} className={styles.configGridContent}>
                  <Typography className={styles.textSub}>
                    Hacer devoluciones no referenciadas
                  </Typography>
                  <ThemeProvider theme={switchTheme}>
                    <Switch
                      key={"allowUnrefRefundSwitch"}
                      checked={state.allowUnreferencedRefund}
                      onChange={handleUnrefRefMode}
                      inputProps={{ "aria-label": "controlled" }}
                      name={"allowUnrefRefundSwitch"}
                      disabled={role === "Operador" ? true : false}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid xs={12} className={styles.configGridContent}>
                  {validManufacturer === "Newland" ? (
                    <>
                      <Typography className={styles.textSub}>
                        Habilitar cobro con Modo API
                      </Typography>
                      <ThemeProvider theme={switchTheme}>
                        <Switch
                          key={"allowApiModeSwitch"}
                          checked={currentAPIMode}
                          onChange={handleApiMode}
                          inputProps={{ "aria-label": "controlled" }}
                          name={"allowApiModeSwitch"}
                          disabled={role === "Operador" ? true : false}
                        />
                      </ThemeProvider>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* 
          <Grid item xs={4} xl={4}>
            <Card
              className={styles.cardInternal}
              style={{ paddingInline: "1.5em" }}
            >
              <Grid className={styles.gridInternal}>
                <Grid container>
                  <Grid item xs={12} xl={12}>
                    
                    <Typography
                      className={styles.textCardSetting}
                      style={{
                        marginLeft: "1em",
                        marginTop: "1.2em",
                        marginBottom: "1.5em",
                      }}
                    >
                      Funcionalidades
                    </Typography>
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    <Grid container>
                      <Grid xs={6} className={styles.configGridContent}>
                        <Typography
                          className={styles.textFunction}
                          sx={{ marginBottom: "1em" }}
                        >
                          Multicomercio:
                        </Typography>
                      </Grid>
                      <Grid xs={6} className={styles.configGridContent} sx={{ justifyContent: "center !important"}}> 
                        <Chip label={!!state.allowMultiMerchant ? "Habilitado" : "Deshabilitado"} className={ styles.functionChip } sx={{ backgroundColor: !!state.allowMultiMerchant ? colors.enabledChip : colors.disabledChip }}/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    <Grid container>
                      <Grid xs={6} className={styles.configGridContent}>
                        <Typography
                          className={styles.textFunction}
                          sx={{ marginBottom: "1em" }}
                        >
                          ClaveDNI:
                        </Typography>
                      </Grid>
                      <Grid xs={6} className={styles.configGridContent} sx={{ justifyContent: "center !important"}}> 
                        <Chip label={!!state.allowClaveDNI ? "Habilitado" : "Deshabilitado"} className={ styles.functionChip } sx={{ backgroundColor: !!state.allowClaveDNI ? colors.enabledChip : colors.disabledChip }}/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    <Grid container>
                      <Grid xs={6} className={styles.configGridContent} sx={{ height: '100%' }}>
                        <Typography
                          className={styles.textFunction}
                          sx={{ marginBottom: "1em" }}
                        >
                          ID terminal clave DNI:
                        </Typography>
                      </Grid>
                      <Grid xs={6} className={styles.configGridContent} sx={{ justifyContent: "center !important"}}> 
                        <Typography className={styles.textSub}>
                          {state.codTerminalClaveDNI
                            ? state.codTerminalClaveDNI
                            : "No disponible"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          */}
        </Grid>
      </Card>

      {/* Modificar tarjetas y dialogs en general*/}
      <Card
        className={styles.cardInternalFilter}
        style={{ paddingInline: "2em" }}
      >
        <Grid container spacing={2} className={styles.brandsGridContent}>
          <Grid item xs={12} style={{ paddingTop: 5, width: "90%" }}>
            <ErrorBoundary>
              <CardSetting
                tittle={"Tarjetas disponibles"}
                state={cardList}
                merchant={merchant}
                terminal={terminal}
                enabledCardsCounter={enabledProducts.length}
                multiMerchantGroup={state.multiMerchantGroups || []}
                enabledMultiMerchant={state.allowMultiMerchant}
              />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Card>

      {/* ////// DIALOGS ////// */}
      <DialogCustom
        setOpen={openPrismaDialog}
        title="Ingresá el nuevo número de comercio Prisma"
        description={descriptionPrisma}
        loading={loading}
        handleClose={handleClose}
        action={() => {
          newPrismaMerchant.length !== prismaMaxLength
            ? null
            : updateTefAcquirers(validMID, "13", validTID, newPrismaMerchant);
        }}
      />
      <DialogCustom
        setOpen={openAMEXDialog}
        title="Ingresá el nuevo número de comercio AMEX"
        description={descriptionAmex}
        loading={loading}
        handleClose={handleClose}
        action={() => {
          newAmexMerchant.length !== amexMaxLength
            ? null
            : updateTefAcquirers(validMID, "14", validTID, newAmexMerchant);
          //handleClose()
        }}
      />
      <DialogCustom
        setOpen={openCashbackDialog}
        title={
          !currentCashback
            ? "Habilitar cobro y retiro de efectivo (Débito)"
            : "Deshabilitar cobro y retiro de efectivo (Débito)"
        }
        description={descriptionCashback}
        loading={loading}
        handleClose={handleClose}
        action={() => allowCashbackAPI("DEBIT")}
      />
      <DialogCustom
        setOpen={openApiModeDialog}
        title={
          !currentAPIMode
            ? "Estás a punto de habilitar el cobro con Modo API"
            : "Estás a punto de deshabilitar el cobro con Modo API"
        }
        description={descriptionAPIMode}
        loading={loading}
        handleClose={handleClose}
        action={toggleAPIMode}
      />
      <DialogCustom
        setOpen={openUnrefRefundDialog}
        title={
          !state.allowUnreferencedRefund
            ? "Estás a punto de habilitar devoluciones no referenciadas"
            : "Estás a punto de deshabilitar devoluciones no referenciadas"
        }
        description={descriptionUnrefRefund}
        loading={loading}
        handleClose={handleClose}
        action={toggleUnrefRefund}
      />
      <Dialog
        open={openPkgDialog}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          color={colors.primary}
          sx={{
            margin: "1em",
            fontSize: "20px",
          }}
        >
          {"Configuración específica de la terminal"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <pre
            className="layout__item u-1/2-lap-and-up"
            style={{ margin: ".5em", lineHeight: 1.8 }}
          >
            {JSON.stringify(specificPkg, null, 2)}
          </pre>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ width: "50%", margin: "1em", color: colors.primary }}
          >
            Cerrar
          </Button>
          <Button
            onClick={copyPkgToClipboard}
            sx={{ width: "50%", margin: "1em", color: colors.primary }}
            autoFocus
          >
            Copiar información
          </Button>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message="Copiado al portapapeles"
            action={action}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Settings;
