import React, { useState, useEffect, useContext, useCallback } from "react";
import colors from "constants/colors";
//import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import enUS from "date-fns/locale/en-US";
import Skeleton from "@mui/material/Skeleton";
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import TimePicker from "@mui/lab/TimePicker";
import TableCustom from "./table";
import TerminalService from "../../services/terminal.service";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";
import RoleContext from "context/userRole/RoleContext";
import MixPanelService from "services/mixpanel.service";
import "./hideArrows.css";
import { format } from "date-fns";
import mixpanel from "mixpanel-browser";

type Props = any;

const style = {
  bgcolor: "background.paper",
  border: `1px solid #E9EDF5`,
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ErrorsHistory: React.FC<Props> = () => {
  const windowSize = useWindowSize();

  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);
  //Context
  const { role } = useContext(RoleContext);

  const handleDrawerOpen = React.useCallback(() => {
    setUpdateDrawer(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setUpdateDrawer(false);
  }, []);

  //open Drawer in width 1366
  useEffect(() => {
    if (windowSize.width >= 1366) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [windowSize.width]);

  const styles = useStyles();

  const [localErrors, setLocalErrors] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchPage, setFetchPage] = useState<number>(1);
  const [queryFromDate, setQueryFromDate] = useState<Date>(new Date());

  const [queryBin, setQueryBin] = useState<string>("");
  const [queryTerminalId, setQueryTerminalId] = useState<string>("");
  const [queryCode, setQueryCode] = useState<string>("");
  const [queryMessage, setQueryMessage] = useState<string>("");
  const [filtered, setFiltered] = useState<boolean>(false);

  const [queryInit, setQueryInit] = useState<Date>(() => {
    const initDate = new Date(queryFromDate);
    const newHours = initDate.getHours() ? initDate.getHours() - 1 : 0;
    initDate.setHours(newHours, initDate.getMinutes(), 0, 0);
    return initDate;
  });

  const [queryFinish, setQueryFinish] = useState<Date>(() => {
    const finishDate = new Date(queryFromDate);
    finishDate.setHours(queryFromDate.getHours(), queryFromDate.getMinutes(), 0, 0);
    return finishDate;
  });

  const auxTimeInit = queryInit && new Date(queryInit);

  const checkTimeInput = (event: any) => {
    setFiltered(false);
    const [hours, minutes] = event.target.value.split(":").map(Number);

    if (hours > 23 || minutes > 59) {
      event.target.blur();
    }
  };

  useEffect(() => {
    if (filtered && queryInit && queryFinish) {
      fetchErrorsHistory(fetchPage, queryInit, queryFinish, queryTerminalId, queryCode, queryMessage, queryBin);
    }
  }, [filtered, queryInit, queryFinish, fetchPage]); // Se ejecuta cuando estos valores cambian

  const setAndFetchData = () => {
    setFormatToFilters();
    setFiltered(true);
  };


  const fetchErrorsHistory = async (
    currentPage: number,
    dateFrom: Date,
    dateTo: Date,
    terminalId: string | null,
    errorCode: string | null,
    errorMessage: string | null,
    bin: string | null
  ) => {
    try {
      setLoading(true);
      const res = await TerminalService.local_errors({
        current_page: currentPage,
        page_size: 100,
        date_from: dateFrom.toISOString(),
        date_to: dateTo.toISOString(),
        terminal_id: terminalId === "" ? null : terminalId,
        error_code: errorCode === "" ? null : errorCode,
        error_message: errorMessage === "" ? null : errorMessage,
        bin: bin === "" ? null : bin
      });
      if (res.status === 200) {
        setLoading(false);
        setLocalErrors(res.data);
        mixpanel.track("errors_history_screen", {
          Screen: "Errors History - Filter",
          Filter: {
            from: dateFrom.toISOString(),
            to: dateTo.toISOString(),
            terminalId: terminalId || "",
            errorCode: errorCode || "",
            errorMessage: errorMessage || "",
            bin: bin || "",
            page: fetchPage
          }
        });
      }
    } catch (error: any) {
      setLoading(false);
      setLocalErrors(null);
    }
  };

  const setFormatToFilters = () => {
    if (queryInit) {
      const auxQueryInit = new Date(queryFromDate);
      auxQueryInit.setHours(queryInit.getHours(), queryInit.getMinutes(), 0, 0);
      setQueryInit(auxQueryInit)
    }

    if (queryFinish) {
      const auxQueryFinish = new Date(queryFromDate);
      auxQueryFinish.setHours(queryFinish.getHours(), queryFinish.getMinutes(), 0, 0);
      setQueryFinish(auxQueryFinish);
    }
  }

  const validateEmpty = queryFromDate == null && queryInit == null && queryFinish == null
    && queryTerminalId == "" && queryCode == "" && queryMessage == "" && queryBin == "" ? true : false;

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    setQueryFromDate(new Date());
    setQueryTerminalId("");
    setQueryCode("");
    setQueryMessage("");
    setQueryBin("");

    const auxQueryInit = new Date(queryFromDate);
    const newHours = auxQueryInit.getHours() ? auxQueryInit.getHours() - 1 : 23;
    auxQueryInit.setHours(newHours, auxQueryInit.getMinutes(), 0, 0);
    setQueryInit(auxQueryInit);

    const auxQueryFinish = new Date(queryFromDate);
    auxQueryFinish.setHours((auxQueryFinish.getHours()), auxQueryFinish.getMinutes(), 0, 0);
    setQueryFinish(auxQueryFinish);
  };

  return (
    <Grid
      className={styles.root}
      style={{
        paddingLeft: drawerOpen
          ? windowSize.width < 1200
            ? "5rem"
            : "17rem"
          : "5rem",
      }}
    >
      <Card
        elevation={0}
        sx={{
          ...style,
          flexGrow: 1,
          boxShadow: "none"
        }}
      >
        <Typography
          style={{
            fontSize: "30px",
            paddingTop: "0.5em",
            color: colors.primary,
          }}
        >
          Historial de Errores
        </Typography>
        <Grid container spacing={2} paddingTop=".8rem">
          <Grid item xs={12} xl={12}>
            <Card className={styles.cardInternal}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <Grid
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid style={{ paddingRight: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={
                            <span style={{ color: colors.grayText }}>Fecha</span>
                          }
                          value={queryFromDate}
                          inputFormat={"dd/MM/yyyy"}
                          minDate={new Date("2010-01-01")}
                          maxDate={new Date()}
                          onChange={(newValue: any) => {
                            setFiltered(false);
                            if (newValue) {
                              setQueryFromDate(newValue);

                              const auxQueryInit = new Date(newValue);
                              const newInitHour = auxQueryInit.getHours() ? auxQueryInit.getHours() - 1 : 0;
                              auxQueryInit.setHours(newInitHour, auxQueryInit.getMinutes(), 0, 0);
                              setQueryInit(auxQueryInit);

                              const auxQueryFinish = new Date(newValue);
                              const newFinishMins = auxQueryFinish.getHours() == 23
                                && auxQueryFinish.getMinutes() == 0
                                ? 59
                                : auxQueryFinish.getMinutes();
                              auxQueryFinish.setHours(auxQueryFinish.getHours(), newFinishMins, 0, 0);
                              setQueryFinish(auxQueryFinish);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid style={{ paddingRight: "1rem", width: "6.5rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
                        <TimePicker
                          ampm={true}
                          openTo="hours"
                          views={["hours", "minutes"]}
                          inputFormat="HH:mm"
                          mask="__:__"
                          label={
                            <span style={{ color: colors.grayText }}>
                              Horario
                            </span>
                          }
                          minTime={new Date(0, 0, 0, 0, 0, 0)} // Asegura que siempre sea 00:00
                          maxTime={
                            queryFromDate.toDateString() === new Date().toDateString()
                              ? new Date(new Date().setHours(new Date().getHours() - 1, new Date().getMinutes(), 0, 0))
                              : new Date(0, 0, 0, 23, 59, 0) // Asegura que sea 23:59 en días pasados
                          }
                          value={queryInit}
                          onChange={(newValue) => {
                            setFiltered(false);
                            if (newValue) {
                              const newQueryInit = new Date(queryFromDate);
                              newQueryInit.setHours(newValue.getHours(), newValue.getMinutes(), 0, 0);
                              setQueryInit(newQueryInit);
                            }
                          }}
                          onOpen={() =>
                            //mixpanel Carga de Apps install
                            MixPanelService.sendDataMixPanel(
                              "start_hour_clock",
                              "Diagnostics - Historial de Errores",
                              role
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onFocus={() =>
                                //mixpanel Carga de Apps install
                                MixPanelService.sendDataMixPanel(
                                  "start_hour_input_field",
                                  "Diagnostics - Historial de Errores",
                                  role
                                )
                              }
                              onChange={(e) => {
                                checkTimeInput(e);
                              }}
                              //const valueNew = value.target.value.split(':');
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "hh:mm",
                              }}
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      style={{
                        paddingRight: "1rem",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography>a</Typography>
                    </Grid>
                    <Grid style={{ paddingRight: "1rem", width: "6.5rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
                        <TimePicker
                          ampm={true}
                          openTo="hours"
                          views={["hours", "minutes"]}
                          inputFormat="HH:mm"
                          mask="__:__"
                          label=""
                          value={queryFinish}
                          minTime={
                            auxTimeInit
                              ? new Date(auxTimeInit.getTime() + 60 * 1000) // Agrega 1 minuto sin modificar auxTimeInit
                              : null
                          }
                          maxTime={
                            queryFromDate.toDateString() === new Date().toDateString()
                              ? new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes(), 0, 0))
                              : new Date(0, 0, 0, 23, 59, 0) // Asegura que sea 23:59 en días pasados
                          }
                          onChange={(newValue) => {
                            setFiltered(false);
                            if (newValue) {
                              const newQueryFinish = new Date(queryFromDate);
                              newQueryFinish.setHours(newValue.getHours(), newValue.getMinutes(), 0, 0);
                              setQueryFinish(newQueryFinish);
                            }
                          }}
                          onOpen={() =>
                            //mixpanel Carga de Apps install
                            MixPanelService.sendDataMixPanel(
                              "end_hour_clock",
                              "Diagnostics - Historial de Errores",
                              role
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onFocus={() =>
                                //mixpanel Carga de Apps install
                                MixPanelService.sendDataMixPanel(
                                  "end_hour_input_field",
                                  "Diagnostics - Historial de Errores",
                                  role
                                )
                              }
                              onChange={(event) => {
                                checkTimeInput(event);
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "hh:mm",
                              }}
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <TextField
                      style={{ paddingRight: "1em" }}
                      id="card-textfield-terminal"
                      type="text"
                      variant="standard"
                      label="Terminal ID"
                      className="search"
                      value={queryTerminalId}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryTerminalId(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <TextField
                      style={{ paddingRight: "1em" }}
                      id="card-textfield-code"
                      type="text"
                      variant="standard"
                      label="Código de error"
                      className="search"
                      value={queryCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryCode(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <TextField
                      style={{ paddingRight: "1em" }}
                      id="card-textfield-message"
                      type="text"
                      variant="standard"
                      label="Mensaje de error"
                      className="search"
                      value={queryMessage}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryMessage(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <TextField
                      style={{ paddingRight: "1em" }}
                      id="card-textfield-bin"
                      type="text"
                      variant="standard"
                      label="Primeros 6 dígitos"
                      className="search"
                      value={queryBin}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryBin(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <Grid style={{ paddingRight: "3rem" }}>
                      {filtered && !validateEmpty ? (
                        <IconButton
                          color="primary"
                          style={{ width: "10em" }}
                          className={styles.buttomFilter}
                          onClick={() => (
                            handleReset(),
                            setFiltered(false),
                            mixpanel.track("apply_filter_errors_history", {
                              Screen: "Diagnostics - Errors History - Clear filters"
                            })
                          )}
                        >
                          Borrar filtros
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : !loading ? (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                          onClick={() => (setFetchPage(1),
                            setAndFetchData())}
                        >
                          Aplicar
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                        >
                          <div
                            className="rotateIcon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "2rem",
                              height: "2rem",
                            }}
                          >
                            <LoadingIcon />
                          </div>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </ErrorBoundary>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} xl={12}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                style={{
                  background: colors.background,
                  borderRadius: "10px",
                }}
                width={"100%"}
                height={"41.2rem"}
              />
            ) : localErrors != undefined
              && localErrors.total_errors != undefined
              && localErrors.total_errors > 0 ? (
              <Card className={styles.cardTable}>
                <Grid className={styles.gridInternal}>
                  <ErrorBoundary>
                    <TableCustom
                      state={localErrors.errors}
                      fetchPage={fetchPage}
                      setFetchPage={setFetchPage}
                      total_elements={localErrors?.total_errors}
                      total_pages={localErrors?.total_pages}
                    />
                  </ErrorBoundary>
                </Grid>
              </Card>
            ) : (
              <div
                style={{
                  width: "100%",
                  minHeight: "41.2rem",
                }}
              >
                <AlertMessage
                  message={"¡No se encontraron eventos!"}
                  customStyle={{ paddingTop: "8rem", paddingBottom: "2rem" }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
export default ErrorsHistory;
