import React from "react";
import Typography from "@mui/material/Typography";
//Prevented Error Component
import ErrorBoundary from "components/errorBoundary";
import useStyles from "../ResumenStyles";
import { format /* , sub */, parseISO } from "date-fns";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface Props {
  deviceManufacturer: string;
  deviceName: string;
  createdAt: string;
  ICC: string;
  operadora: string;
  storageSize: number;
  storageFree: number;
  memorySize: number;
  memoryFree: number;
  battery: number;
  firmware: string;
}

function capitalizeFirstLetter(str: string) {
  if (!str) return;
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1AAAAD" : "#308fe8",
  },
}));

const General = ({
  deviceManufacturer,
  deviceName,
  createdAt,
  ICC,
  operadora,
  storageSize,
  storageFree,
  memorySize,
  memoryFree,
  battery,
  firmware,
}: Props) => {
  const styles = useStyles();
  const storageUsed: number = ((storageSize - storageFree) * 100) / storageSize;
  const memoryUsed: number = ((memorySize - memoryFree) * 100) / memorySize;

  return (
    <>
      <Typography
        className={styles.textTittle}
        style={{
          paddingTop: ".4em",
          paddingLeft: ".4em",
        }}
      >
        Información general
      </Typography>
      <ErrorBoundary>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>
            Último diagnóstico
          </Typography>
          {createdAt && (
            <Typography className={styles.textSub}>
              {format(parseISO(createdAt), "dd/MM/yyyy - HH:mm")}
            </Typography>
          )}
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>Marca y modelo</Typography>
          <Typography className={styles.textSub}>
            {capitalizeFirstLetter(deviceManufacturer) + " " || ""}
            {deviceName || ""}
          </Typography>
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>
            Operadora y SIM
          </Typography>
          <Typography className={styles.textSub}>
            {operadora ? operadora + " - " : ""}
            {ICC || "No disponible"}
          </Typography>
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>Batería</Typography>
          <div className={styles.textSub}>
            <BorderLinearProgress
              variant="determinate"
              value={battery}
              style={{ width: "75%" }}
            />
            <div style={{ paddingTop: "0.5em" }}>
              {"Nivel: " + battery + "%"}
            </div>
          </div>
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>Almacenamiento</Typography>
          {storageSize && storageFree ? (
            <div className={styles.textSub}>
              <BorderLinearProgress
                variant="determinate"
                value={storageUsed}
                style={{ width: "75%" }}
              />
              <div style={{ paddingTop: "0.5em" }}>
                {`Utilizados ${(storageSize - storageFree).toFixed(
                  2
                )} de ${storageSize} GB`}
              </div>
            </div>
          ) : (
            <Typography className={styles.textSub}>No disponible</Typography>
          )}
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>Memoria</Typography>
          {memorySize && memoryFree ? (
            <div className={styles.textSub}>
              <BorderLinearProgress
                variant="determinate"
                value={memoryUsed}
                style={{ width: "75%" }}
              />
              <div style={{ paddingTop: "0.5em" }}>
                {`Utilizados ${(memorySize - memoryFree).toFixed(
                  2
                )} de ${memorySize} MB`}
              </div>
            </div>
          ) : (
            <Typography className={styles.textSub}>No disponible</Typography>
          )}
        </div>
        <div className={styles.div}></div>
        <div className={styles.dataContainer}>
          <Typography className={styles.textSubBold}>
            Firmware
          </Typography>
          <Typography className={styles.textSub}>
            {firmware || "-"}
          </Typography>
        </div>

      </ErrorBoundary>
    </>
  );
};

export default General;
